<template>
  <div v-editable="blok">
    <div class="container">
      <CardList :cards="cards" />
    </div>
  </div>
</template>

<script setup lang="ts">
import type {
  BlockNewsListCuratedStoryblok,
  ContentTypeNewsArticleStoryblok,
} from '@/types/storyblok'
import type { CardListCard } from '@/components/card/CardList.vue'
import { formatLink } from '@/utils/link-utils'
import type { FetchAPIOptions } from '@/composables/useStoryblokHelpers'

const { fetchAsyncData } = useStoryblokHelpers()

const props = defineProps<{
  blok: BlockNewsListCuratedStoryblok
}>()

const defaultPerPage = 2
const perPage = computed(() =>
  props.blok.quantity ? parseInt(props.blok.quantity) : defaultPerPage,
)

const uuids = computed(() => props.blok.articles?.join(',') || '')
const community = computed(() =>
  isContentTypeCommunityStoryblok(props.blok.community)
    ? props.blok.community
    : undefined,
)
const type = computed(() => props.blok.type)

const apiOptions = computed<FetchAPIOptions>(() => ({
  content_type: 'ContentTypeNewsArticle',
  sort_by: 'first_published_at:desc',
  page: 1,
  excluding_fields: 'introduction,content',
  // Filter articles by community
  ...(type.value === 'community' && {
    starts_with: community.value?.full_slug,
    per_page: perPage.value,
  }),
  // Filter articles by uuid
  // Filtering by Community takes precedence over manually selected articles
  ...(type.value === 'manual' && {
    by_uuids_ordered: uuids.value,
  }),
}))

const { data, clear, refresh } =
  await fetchAsyncData<ContentTypeNewsArticleStoryblok>('stories', apiOptions, {
    getCachedData(key, nuxtApp) {
      return nuxtApp.payload.data[key] || nuxtApp.static.data[key]
    },
  })

const cards = computed(
  () =>
    data.value?.data.stories.map(
      (article) =>
        ({
          _uid: article.uuid,
          title: article.name,
          description: article.content.shortDescription,
          image: article.content.featuredImage,
          link: formatLink(article.full_slug),
        }) as CardListCard,
    ) || [],
)

watch(
  [uuids, perPage, community, type],
  () => {
    clear()
    refresh()
  },
  { deep: true },
)
</script>
